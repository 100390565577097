<script>
import TemplateMixin from '../mixin'
import {list} from '../../../../../domain/templates/services'
import windowMenu from './window/windowMenu'
import windowMenusList from './window/windowMenuList'

export default {
  name: 'PageMenus',
  mixins: [TemplateMixin],
  data () {
    return {
      loading: false,
      templates: null,
      categories: null,
      categoria: null,
      buscaRight: null
    }
  },
  computed: {},
  mounted () {},
  destroyed () {
  },
  methods: {
    windowMenu,
    windowMenusList,
    criar () {
      this.windowMenu()
    },
    listar () {
      this.windowMenusList()
    }
  },
  meta: {
    title: 'Menus',
    name: 'Menus'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap menu-site-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <header class="menu-site-header">
          <h1 class="menu-site-header-title">Automação de Menus</h1>
          <h2 class="menu-site-header-subtitle">
            Configure todos os menus do seu site
            <a href="https://help.suporteleiloes.com/templates" target="_blank" title="Ajuda" tabindex="0">Ajuda</a>
          </h2>
        </header>

        <div class="menu-site-body">
          <div class="box-connect"></div>
          <div class="tbox box-right">
            <div class="tbox-content">
              <h1 class="content-title text-center disabled">Selecione uma ação</h1>

              <div class="text-center m-t">
                <e-btn @click="criar" label="Criar novo menu" />
              </div>
              <div class="text-center m-t">
                <e-btn @click="listar" label="Listar menus criados" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
